<template>
  <v-container fluid>
    <v-form
      ref="visitNoteForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="createVisitNote"
    >
      <v-container fluid>
        <v-card flat class="custom-grey-border remove-border-radius">
          <v-card-text class="p-6 font-size-16">
            <v-textarea
              v-model.trim="visitNote"
              :rules="
                visitNoteActive
                  ? [() => !!visitNote || 'Notes is required']
                  : []
              "
              auto-grow
              dense
              filled
              flat
              :disabled="pageLoading"
              label="Notes"
              solo
              hide-details
              row-height="20"
              :readonly="!visitNoteActive"
              v-on:dblclick="visitNoteActive = true"
            ></v-textarea>
            <div v-if="visitNoteActive" class="mt-4">
              <FileTemplate
                v-on:file:updated="updateFiles"
                :allowUpload.sync="visitNoteActive"
              ></FileTemplate>
            </div>
            <div
              v-if="visitNoteActive"
              class="text-right custom-border-top py-4 mt-4"
            >
              <v-btn
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                :loading="formLoading"
                :disabled="!formValid || formLoading"
                v-on:click="createVisitNote"
              >
                Save
              </v-btn>
              <v-btn
                :disabled="formLoading"
                class="custom-grey-border custom-bold-button"
                v-on:click="closeNote"
              >
                Cancel
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-form>
    <v-container fluid>
      <template v-if="notes.length > 0">
        <v-card
          flat
          class="custom-grey-border remove-border-radius mb-4"
          v-for="(note, index) in notes"
          :key="index"
        >
          <v-card-title
            class="gray-background custom-border-bottom py-0"
            :key="'visit-note-card-title' + index"
          >
            <v-row>
              <v-col md="1" cols="2" class="text-center">
                <v-avatar color="cyan">
                  <span class="white--text headline">{{
                    getShortName(note.added_by.display_name)
                  }}</span>
                </v-avatar>
              </v-col>
              <v-col md="11" cols="10">
                <v-list-item-title
                  class="font-size-16 font-weight-500"
                  v-html="note.added_by.display_name"
                ></v-list-item-title>
                <v-tooltip top content-class="custom-top-tooltip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item-subtitle
                      class="font-size-14 max-content-width"
                      v-html="note.created_at"
                      v-bind="attrs"
                      v-on="on"
                    ></v-list-item-subtitle>
                  </template>
                  <p class="m-0 custom-nowrap-ellipsis">
                    {{ formatDateTime(note.added_at) }}
                  </p>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text :key="'visit-note-card-text-0' + index" class="pt-4">
            <read-more
              class="custom-grey-border custom-read-more px-4 py-4 font-weight-600 font-size-16 custom-gray-color"
              more-str="read more"
              :text="lodash.isEmpty(note) === false ? note.notes : ''"
              link="#"
              less-str="read less"
              :max-chars="200"
            >
            </read-more>
          </v-card-text>
          <v-card-text
            v-if="lodash.isEmpty(note.documents) === false"
            :key="'visit-note-card-text-1' + index"
            class="pt-0"
          >
            <FileTemplate
              :attachments.sync="note.documents"
              v-on:file:updated="updateFiles"
            ></FileTemplate>
          </v-card-text>
        </v-card>
      </template>
    </v-container>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import { PUT, GET } from "@/core/services/store/request.module";
export default {
  mixins: [CommonMixin],
  props: {
    pageLoading: {
      required: true,
      type: Boolean,
      default: false
    },
    detail: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    visit: {
      required: true,
      type: Object,
      default() {
        return new Object();
      }
    },
    notes: {
      required: true,
      type: [Array, Object],
      default() {
        return new Array();
      }
    }
  },
  data() {
    return {
      visitNoteActive: false,
      visitNote: null,
      visitNoteDocument: new Array()
    };
  },
  components: {
    FileTemplate
  },
  methods: {
    createVisitNote() {
      const _this = this;

      if (!_this.$refs.visitNoteForm.validate()) {
        return false;
      }
      _this.formLoading = true;

      _this.$store
        .dispatch(PUT, {
          url: "job/" + _this.detail.id + "/visit/" + _this.visit.id + "/note",
          data: {
            notes: _this.visitNote,
            documents: _this.visitNoteDocument
          }
        })
        .then(({ data }) => {
          _this.notes.unshift(data);
          //_this.getVisit(_this.visit);
          _this.closeNote();
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getVisit(id) {
      const _this = this;
      let requestUrl = "job/" + _this.detail.id + "/visit/" + id;
      _this.$store
        .dispatch(GET, { url: requestUrl })
        .then(({ data }) => {
          _this.visitDetail = data;
          _this.visitNotes = data.notes;
          _this.visitDetailDialog = true;
          _this.visitDeleteRequestURL = requestUrl;
        })
        .catch(error => {
          _this.logError(error);
        });
    },
    updateFiles(param) {
      this.visitNoteDocument = param;
    },
    closeNote() {
      this.formValid = true;
      this.visitNote = null;
      this.visitNoteDocument = new Array();
      this.visitNoteActive = false;
    }
  }
};
</script>
